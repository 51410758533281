import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import axios from "axios";
import SectionHead from "../components/SectionHead";
import MessageBlock from "../components/MessageBlock";
import { PORetroCard } from "../elements/RetroCard";
import { setIsShowPO } from "../store/features/controlling/showHide";
import _ from "underscore";

const SuppliersDetailPage = () => {
  const [supplierData, setSupplierData] = useState({});
  const [supplierInfo, setSupplierInfo] = useState({});
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [popupData, setPopupData] = useState({});
  const isShowPO = useSelector((state) => state.showHide.isShowPO);
  const dispatch = useDispatch();
  let date = new Date() 
  date.setDate(1)
  date.setMonth(date.getMonth())  

  let location = useLocation();

  useEffect(() => {
    const fetchSupplier = async () => {
      const cloudData = await axios.get(
        `https://exenpy.adabrain.com/suppliersByCode${location.search}`
      );
      let data = cloudData.data;
      data.value.poItems = data.value.poItems.reverse();
      setSupplierData(data.value);
      setSupplierInfo(
        supplierData.poItems ? supplierData.poItems[0].supName : {}
      );
    };

    fetchSupplier();
    const interval = setInterval(fetchSupplier, 1000 * 60 * 15);
    return () => clearTimeout(interval);
  }, [location, supplierData]);

  const RenderSupplierInfo = () => (
    <span className="text-md">
      <span className="mb-4">
        <b>Supplier Code: </b> {supplierInfo.supCode}
      </span>
      <br />
      <br />
      <span className="mb-4">
        <b>Address: </b> {supplierInfo.address}
      </span>
      <br />
      <br />
      <span className="mb-4">
        <b>PO Amount: </b> {supplierData.count}
      </span>
      <br />
      <br />
      <span className="mb-4">
        <b>PO Total Value: </b>{" "}
        {new Intl.NumberFormat().format(supplierData.value)} THB
      </span>
    </span>
  );

  const poPopupController = (poItems) => {
    console.log(poItems);
    setIsOpenPopup(!isOpenPopup);
    if (poItems) setPopupData(poItems);
  };

  const RenderPoPopup = () => {
    return (
      <div className={`${isOpenPopup ? "visible" : "invisible"}`}>
        <div className="absolute bg-white w-1/2 h-1/3 z-10 top-4 left-1/4 rounded-md p-4">
          {popupData ? popupData.poNum : "No Po Number"}
        </div>
        <div
          className={`absolute flex top-0 left-0 w-full h-full bg-black opacity-90 justify-center 
            z-0
          `}
          onClick={() => poPopupController()}
        ></div>
      </div>
    );
  };

  const RenderPoItems = () =>
    _.map(supplierData.poItems, (v, k) => {      
        return (
          <PORetroCard
            key={k}
            data={v}
            onClickFn={() => poPopupController(v)}
          />
        );
    });

  return (
    <>
      <div className="text-white drop-shadow-xl mb-2">
        <SectionHead
          title={`Supplier name: ${supplierData.supName}`}
          textColors="from-yellow-400 to-pink-600 text-2xl"
        />

        <MessageBlock title="Information" body={<RenderSupplierInfo />} />
        <br />
        <SectionHead
          title="All POs from the supplier"
          textColors="from-yellow-400 to-pink-600 text-2xl"
          isHasButton={true}
          onClickFn={() => dispatch(setIsShowPO({ isShowPO: !isShowPO }))}
        />
        <div className="flex flex-wrap flex-row justify-start justify-items-stretch gap-4 px-2 my-6">
          {isShowPO ? <RenderPoItems /> : ""}
        </div>
      </div>

      <RenderPoPopup />
    </>
  );
};

export default SuppliersDetailPage;
