const MessageBlock = (props) => {
  const { title, body, time } = props
  return (
    <div className="nes-container is-dark with-title text-xl">
      <p className="title">{title}</p>
      <p>{body}</p>
      <p className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 via-sky-400">{time}</p>
    </div>
  );
};

export default MessageBlock;
