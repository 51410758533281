import { setName } from "../store/features/grouping/groupingSlice";
import { useSelector, useDispatch } from "react-redux";

const Badge = (props) => {
  const { text, color } = props;
  
  const activeName = useSelector(state => state.grouping.name)
  const dispatch = useDispatch();
  const isActive = activeName === text ? "invert" : ""

  return (
    <button
      className={`rounded-lg bg-gradient-to-b ${color.from} ${color.to} px-2 py-2 text-sm shadow-lg shadow-slate-900 hover:invert ${isActive}`}
      onClick={() => dispatch(setName({name: text}))}
    >
      <span className="text-white text-xs">{text}</span>
    </button>
  );
};

export default Badge;
