import SectionHead from "../components/SectionHead";
import MessageBlock from "../components/MessageBlock";
import { GiOpenTreasureChest } from "react-icons/gi";
import StocksTable from "../components/StocksTable";
import { useEffect, useState } from "react";
import axios from "axios";

const StockTablePage = () => {
  const [stocksData, setStocksData] = useState({});
  const [updatedTime, setUpdatedTime] = useState("");

  useEffect(() => {
    // Run first time
    const fetchStocks = async () => {
      const cloudData = await axios.get(
        "https://workerkv.simple-ada.workers.dev/api/exen/getStocks"
      );
      setStocksData(cloudData.data);
      setUpdatedTime(new Date(Date.now()).toString());
    };
    fetchStocks();

    const interval = setInterval(fetchStocks, 1000 * 60 * 5);
    return () => clearTimeout(interval);
  }, []);

  return (
    <div className="text-white drop-shadow-xl mb-2">
      <SectionHead
        title={`Stocks Analysis...(Table view)`}
        icon={<GiOpenTreasureChest className="text-yellow-500" />}
        textColors="from-yellow-400 to-pink-600"
      />

      <MessageBlock
        title="Description"
        body={`If you don't mind I would like to let you know the latest update datetime is `}
        time={updatedTime}
      />

      <StocksTable data={stocksData} />
    </div>
  );
};

export default StockTablePage;
