import { createSlice } from "@reduxjs/toolkit";

const showHideSlice = createSlice({
    name: "showHide",
    initialState: {
        isShowPO: true,
    },
    reducers: {
        setIsShowPO: (state, action) => {
            state.isShowPO = action.payload.isShowPO
        }
    }
})

export const { setIsShowPO } = showHideSlice.actions
export default showHideSlice.reducer