import { Outlet } from "react-router-dom";
import SideBar from "./components/SideBar";
import SectionHead from "./components/SectionHead";
import { GiBuyCard } from "react-icons/gi";

const App = () => {
  return (
    <div
      className="flex justify-start lg:gap-3 gap-none relative 
    bg-gradient-to-tr from-slate-900 via-blue-900 to-slate-900"
    >
      <div className="flex flex-col h-screen sticky top-0 ">
        <SideBar />
      </div>
      <div className="w-full h-full flex-col overflow-y-auto py-4 pr-4 text-xs lg:text-sm">
        <SectionHead
          title={`Welcome to LFSSteel Exen V0.9 (Development Versions)`}
          icon={<GiBuyCard className="text-yellow-500" />}
          textColors="from-yellow-400 to-pink-600"
        />
        <Outlet />
      </div>
    </div>
  );
};

export default App;
