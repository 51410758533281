import { useState, useEffect } from "react";
import axios from "axios";
import _ from "underscore";
import SectionHead from "../components/SectionHead";
import MessageBlock from "../components/MessageBlock";
import { FaUserTie } from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  ComposedChart,
  Line,
  LineChart,
  LabelList,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const tableHeader = ["Rank", "Name", "Amount", "Value"];

const SuppliersPage = () => {
  const [suppliersData, setSuppliersData] = useState({});
  const [updatedTime, setUpdatedTime] = useState("");

  const RenderHeadTable = () =>
    _.map(tableHeader, (v, k) => (
      <th
        key={k}
        className="text-yellow-300 border-2 px-2 py-2 text-center text-lg"
      >
        {v}
      </th>
    ));

  const RenderBody = () =>
    _.map(suppliersData, (v, k) => (
      <tr
        key={k}
        className={`text-lg border-2 hover:bg-orange-500 ${
          parseInt(v.value) > 10e6 ? "bg-green-700" : ""
        }`}
      >
        <td className="border-2 text-xl text-center px-2 py-2">{k + 1}</td>
        <td className="border-2 text-xl px-2 py-2">
          <Link
            to={{
              pathname: "/suppliers-detail",
              search: `?year=2022&supcode=${v.supCode}`,
              state: { test: "test" },
            }}
          >
            {v.supName}
          </Link>
        </td>
        <td className="text-center border-2">{v.count}</td>
        <td className="text-right border-2 px-2">
          {new Intl.NumberFormat().format(v.value)}
        </td>
      </tr>
    ));

  useEffect(() => {
    // Run first time
    const fetchSuppliers = async () => {
      const cloudData = await axios.get(
        "https://exenpy.adabrain.com/suppliers?year=2022"
      );
      let strData = cloudData.data;
      let jsonData = _.mapObject(strData, (v, k) =>
        JSON.parse(JSON.stringify(v.value))
      );
      jsonData = _.sortBy(jsonData, "value").reverse();
      setSuppliersData(jsonData);
      setUpdatedTime(new Date(Date.now()).toString());
    };
    fetchSuppliers();

    const interval = setInterval(fetchSuppliers, 1000 * 60 * 15);
    return () => clearTimeout(interval);
  }, []);

  return (
    <>
      <div className="text-white drop-shadow-xl mb-2">
        <SectionHead
          title={`Suppliers Insight 2022`}
          icon={<FaUserTie className="text-yellow-500" />}
          textColors="from-yellow-400 to-pink-600"
        />

        <MessageBlock
          title="Description"
          body={`“If I am selling to you, I speak your language. If I am buying, dann müssen sie Deutsch sprechen.” ~ Willy Brandt`}
          time={updatedTime}
        />

        <div className="grid grid-cols-2">
          <div className="col-1st mt-5 overflow-x-scroll">
            <table className="border-8 rounded-lg bg-[#212529]">
              <thead>
                <tr className="border-2">
                  <RenderHeadTable />
                </tr>
              </thead>
              <tbody>
                <RenderBody />
              </tbody>
            </table>
          </div>
          <div className="col-2nd mt-5">
            <ResponsiveContainer width="100%" height="100%">
              <ComposedChart
                layout="vertical"
                width={500}
                height={400}
                data={suppliersData}
                margin={{
                  top: 30,
                  right: 0,
                  bottom: 0,
                  left: 0,
                }}
              >
                <CartesianGrid stroke="#f5f5f5" horizontal={false} />
                <XAxis
                  type="number"
                  scale="auto"
                  unit=" THB"
                  orientation="top"
                  tickFormatter={value => new Intl.NumberFormat().format(value / 1e+6)+" M"}
                  stroke="#ffffff"
                />
                <YAxis
                  dataKey="0"
                  type="category"                                                                  
                />
                <Tooltip formatter={value => new Intl.NumberFormat().format(value)} />
                <Legend />
                <Area dataKey="value" fill="#8884d8" stroke="#8884d8" />
                <Bar dataKey="value" barSize={30} fill="rgb(249 115 22)" >
                  <LabelList dataKey="supName" position="right" fill="#ffffff" strokeWidth={18} />
                </Bar>
                <Line dataKey="count" stroke="#ff7300" />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuppliersPage;
