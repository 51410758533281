import { createSlice } from "@reduxjs/toolkit"

const groupingSlice = createSlice({
    name: "grouping",
    initialState: {
        data: [],
        name: "GI",
    },
    reducers: {
        setData: (state, action) => {
            state.data = action.payload.data
        },
        setName: (state, action) => {
            state.name = action.payload.name
        }
    }
})

export const { setData, setName } = groupingSlice.actions
export default groupingSlice.reducer