import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setKeyword, setPageSize } from "../store/features/search/searchSlice";
import { GiDespair, GiMailedFist } from "react-icons/gi";
import _ from "underscore";

const SearchBox = () => {
  const dispatch = useDispatch();
  const handleEnter = (event, payload) => {
    if (event.key === "Enter") {
      dispatch(setKeyword(payload));
    }
  };

  return (
    <div className="nes-field mt-3 mr-5 py-2 w-96">
      <input
        type="text"
        id="search-box"
        className="nes-input is-dark"
        placeholder="search by stock code"
        onChange={(e) => dispatch(setKeyword({ keyword: e.target.value }))}
        onKeyPress={(e) => handleEnter(e, { keyword: e.target.value })}
      />
    </div>
  );
};

const SetPageBox = () => {
  const dispatch = useDispatch();
  const pageSize = useSelector((state) => state.search.pageSize);
  const handleEnter = (event) => {
    if (event.key === "Enter") {
      dispatch(setPageSize({ pageSize: parseInt(event.target.value) }));
    }
  };

  return (
    <div className="nes-field mt-3 py-2 w-96">
      <input
        type="text"
        id="search-box"
        className="nes-input is-dark"
        placeholder="Set show items"
        onKeyPress={(e) => handleEnter(e)}
      />
      <p className="py-2 px-1 text-xs">Showing: {pageSize} items/page</p>
    </div>
  );
};

const StocksTable = (props) => {
  const { data } = props;
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(100);
  const [activePage, setActivePage] = useState(0);

  let pageData = [];
  _.map(data, (d) => pageData.push(d));

  const allCode = _.keys(data);
  const dataKeys = _.keys(Object.values(data)[0]);
  const dataKeysNoDate = _.without(dataKeys, "LPURDAT", "LSELDAT");
  const tableHeader = {
    STKCOD: "Code",
    STKDES: "Description",
    LPURPR: "Last Buy",
    LSELLPR: "Last Sell",
    TOTBAL: "Balance",
    TOTVAL: "Stock Value",
  };
  const RenderHeadTable = () =>
    _.map(dataKeysNoDate, (k, i) => (
      <th key={i} className="text-yellow-300 border-2 px-2 py-2">
        {tableHeader[k]}
      </th>
    ));

  const numericData = (n) => n.at(-1).toFixed(2);
  const numericRow = (d, key) => {
    let numberColor = "";
    if (key === "LPURPR")
      numberColor = d.at(-1) >= d.at(-2) ? "text-rose-500" : "text-emerald-400";
    else if (key === "LSELLPR")
      numberColor = d.at(-1) < d.at(-2) ? "text-rose-500" : "text-emerald-400";
    const RowItem = (props) => {
      const { icon, value } = props;
      return (
        <div className="flex justify-evenly items-center">
          {icon}
          {numericData(value)}
        </div>
      );
    };

    const RenderRowItem = (props) => {
      const { color, value } = props;
      switch (color) {
        case "text-rose-500":
          return (
            <RowItem icon={<GiDespair className="text-lg" />} value={value} />
          );
        case "text-emerald-400":
          return (
            <RowItem icon={<GiMailedFist className="text-lg" />} value={value} />
          );
        default:
          return <RowItem  value={value} />
      }
    };

    return (
      <td
        key={d.STKCOD + key}
        className={numberColor + " border-2 px-2 py-2"}
      >
        <RenderRowItem color={numberColor} value={d} />
      </td>
    );
  };

  const RenderBody = () => {
    const keyword = useSelector((state) => state.search.value);
    let resultRows = [];
    if (keyword !== "") {
      const possibleCode = _.filter(allCode, (code) => code.includes(keyword));
      _.map(possibleCode, (code) => resultRows.push(data[code]));
    } else {
      resultRows = pageData.slice(startPage, endPage);
    }

    return _.map(resultRows, (d, k) => (
      <tr key={d.STKCOD} className="hover:bg-neutral-600">
        {_.map(dataKeysNoDate, (key) => {
          if (_.isObject(d[key])) {
            return numericRow(d[key], key);
          }
          return (
            <td key={key + k} className="border-2 px-2 py-2">
              {d[key]}
            </td>
          );
        })}
      </tr>
    ));
  };

  const changePage = (idx, pageSize) => {
    setStartPage(idx * pageSize);
    setEndPage(idx * pageSize + pageSize);
    setActivePage(idx);
  };

  const Paging = () => {
    const pageSize = useSelector((state) => state.search.pageSize);
    const maxRange = Math.ceil(pageData.length / pageSize);
    const pages = _.range(maxRange);
    return (
      <div className="z-10">
        {_.map(pages, (p) => (
          <button
            key={p}
            type="button"
            onClick={() => changePage(p, pageSize)}
            className={`nes-btn ${
              p === activePage ? "is-warning" : "is-primary"
            }`}
          >
            {p + 1}
          </button>
        ))}
      </div>
    );
  };

  return (
    <div className="paging pt-5">
      <Paging />

      <div className="flex justify-start">
        <SearchBox />
        <SetPageBox />
      </div>

      <div className="mt-5 overflow-x-scroll">
        <table className="border-8 rounded-lg bg-[#212529]">
          <thead>
            <tr className="border-2">
              <RenderHeadTable />
            </tr>
          </thead>
          <tbody>
            <RenderBody />
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default StocksTable;
