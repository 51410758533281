import SectionHead from "../components/SectionHead";
import MessageBlock from "../components/MessageBlock";
import { GiBuyCard } from "react-icons/gi";
import _ from "underscore";
import Badge from "../elements/Badge";
import RetroCard from "../elements/RetroCard";
import groupConfig from "../assets/data/grouping.json";
import { useSelector, useDispatch } from "react-redux";
import { setData } from "../store/features/grouping/groupingSlice";
import { useEffect, useState } from "react";
import axios from "axios";

const RenderBadge = () => {
  const tones = [
    "from-rose-600",
    "from-yellow-500",
    "from-pink-400",
    "from-emerald-500",
    "from-orange-400",
  ];
  const allKeys = _.keys(groupConfig);
  const div = Math.floor(allKeys.length / tones.length);
  const prototype = _.chain(allKeys)
    .map((g, idx) => ({
      text: g,
      color: { from: tones[Math.floor(idx / div)], to: `to-purple-900` },
      idx,
    }))
    .value()
    .sort();

  return (
    <div className="flex flex-wrap justify-start items-space gap-x-3 gap-y-4 mt-5 mb-8">
      {_.map(prototype, (p) => (
        <Badge key={p.text} text={p.text} color={p.color} />
      ))}
    </div>
  );
};

const RenderRetroCards = (props) => {
  // @dev
  // dataSet: Arrray

  const { dataSet } = props;

  return (
    <div className="flex flex-wrap flex-row justify-start gap-6 px-2 my-6">
      {_.map(dataSet, (d) => (
        <RetroCard key={d.STKCOD} data={d} />
      ))}
    </div>
  );
};

const StockCardsPage = () => {
  const groupData = useSelector((state) => state.grouping.data);
  const groupName = useSelector((state) => state.grouping.name);
  const dispatch = useDispatch();
  const [stocksData, setStocksData] = useState({});
  useEffect(() => {
    // Run first time
    const fetchStocks = async () => {
      const cloudData = await axios.get(
        "https://workerkv.simple-ada.workers.dev/api/exen/getStocks"
      );
      setStocksData(cloudData.data);
    };
    fetchStocks();

    const interval = setInterval(fetchStocks, 1000 * 60 * 5);
    return () => clearTimeout(interval);
  }, []);

  useEffect(() => {
    if (groupConfig[groupName]) {
      let tempData = [];
      _.map(groupConfig[groupName], (code) => {
        if (stocksData[code]) tempData.push(stocksData[code]);
      });

      dispatch(setData({ data: tempData }));
    }
  }, [groupName, dispatch, stocksData]);

  return (
    <div className="text-white drop-shadow-xl mb-2">
      <SectionHead
        title={`Stocks Analysis...(Cards view)`}
        icon={<GiBuyCard className="text-yellow-500" />}
        textColors="from-yellow-400 to-pink-600"
      />

      <MessageBlock
        title="Description"
        body={`Sephiroth: ......After a long sleep... ...the time...... ...time has......come...`}
      />

      <RenderBadge />

      <RenderRetroCards dataSet={groupData} />
    </div>
  );
};

export default StockCardsPage;
