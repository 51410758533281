import { useSelector } from "react-redux";
import { BiHide, BiShow } from "react-icons/bi"

const SectionHead = (props) => {
  const { title, icon, textColors, isHasButton, onClickFn } = props;
  const isShowPO = useSelector(state => state.showHide.isShowPO)

  const RenderShowHideButton = () => {
    return (
      <div className="px-6 text-2xl cursor-pointer hover:text-gray-500" onClick={onClickFn}>
        { isShowPO ? <BiShow /> : <BiHide /> }
      </div>
    )
  }

  return (
    <div className="border-8 border-double rounded-lg px-3 py-3 mb-5 flex items-center bg-[#212529] text-xl">
      <div className="text-5xl mr-4">{icon}</div>
      <h2 className={`font-bold text-transparent bg-clip-text bg-gradient-to-r ${textColors}`}>
        {title}
      </h2>
      {isHasButton ? <RenderShowHideButton /> : ""}
    </div>
  );
};

export default SectionHead;
