import { useState } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { FaHome, FaShoppingCart, FaUserTie } from "react-icons/fa";
import { GiOpenTreasureChest, GiJoystick } from "react-icons/gi";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";

const SideBar = () => {
  const [isHide, setIsHide] = useState(false);

  return (
    <ProSidebar className="fixed" collapsed={isHide} width="200px">
      <Menu className="h-screen text-xs lg:text-xs" iconShape="circle">
        <MenuItem icon={<FaHome />}>
          <Link to="/">Home</Link>
        </MenuItem>

        <SubMenu title="Stocks" icon={<GiOpenTreasureChest />}>
          <MenuItem icon={<GiOpenTreasureChest />}>
            <Link to="/stocks-table">Table</Link>
          </MenuItem>
          <MenuItem icon={<GiOpenTreasureChest />}>
            <Link to="/stocks-cards">Cards</Link>
          </MenuItem>
        </SubMenu>

        <MenuItem icon={<FaUserTie />}>
          <Link to="/suppliers">Suppliers</Link>
        </MenuItem>

        <MenuItem
          icon={<GiJoystick />}
          onClick={() => setIsHide((state) => !state)}
        >
          Hide
        </MenuItem>
      </Menu>
    </ProSidebar>
  );
};

export default SideBar;
