import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import StockTablePage from "./pages/StockTablePage";
import StockCardsPage from "./pages/StockCardsPage";
import SuppliersPage from "./pages/SuppliersPage";
import { store } from "./store/store";
import { Provider } from "react-redux";
import SuppliersDetailPage from "./pages/SuppliersDetailPage";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />}>
            <Route path="/stocks-table" element={<StockTablePage />} />
            <Route path="/stocks-cards" element={<StockCardsPage />} />            
            <Route path="/suppliers" element={<SuppliersPage />} />
            <Route path="/suppliers-detail" element={<SuppliersDetailPage />} />
          </Route>
        </Routes>
        <Outlet />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
