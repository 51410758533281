const greaterThan = (val1, val2, displayText) => {
  return val1 >= val2 ? (
    <div className="text-emerald-500">{displayText}</div>
  ) : (
    <div className="text-rose-500">{displayText}</div>
  );
};

const RetroRow = (props) => {
  const { label, value } = props;
  return (
    <div className="retro-row grid grid-cols-2">
      <div className="">{label}</div>
      <div className="">{value}</div>
    </div>
  );
};

const priceFormat = (price, isSell) => {
  const prevPrice = price.at(-2);
  const latestPrice = price.at(-1);
  const format = prevPrice ? `${latestPrice} (${prevPrice})` : latestPrice;
  const compareComponent = isSell
    ? greaterThan(latestPrice, prevPrice, format)
    : greaterThan(prevPrice, latestPrice, format);

  return prevPrice ? (
    compareComponent
  ) : (
    <div className="text-yellow-400">{format}</div>
  );
};

const RetroCard = (props) => {
  const { data } = props;
  return (
    <div
      className="outline outline-4 outline-offset-2 rounded px-4 py-4
    bg-opacity-30 bg-cyan-500 shadow-lg shadow-cyan-500/50 backdrop-blur-lg divide-y-2 divide-yellow-400
    hover:saturate-200
    "
    >
      <h3 className="pb-2 max-w-lg">{`${data.STKCOD}: ${data.STKDES}`}</h3>

      <div className="grid grid-rows-3 pt-2">
        <RetroRow label="Last Buy" value={priceFormat(data.LPURPR, false)} />
        <RetroRow label="Last Sell" value={priceFormat(data.LSELLPR, true)} />
        <RetroRow label="Balance" value={data.TOTBAL.at(-1)} />
      </div>
    </div>
  );
};

const PORetroCard = (props) => {
  const { data, onClickFn } = props;
  return (
    <div
      className="outline outline-4 outline-offset-2 rounded px-4 py-4
    bg-opacity-30 bg-cyan-500 shadow-lg shadow-cyan-500/50 backdrop-blur-lg divide-y-2 divide-yellow-400
    hover:saturate-200
    "
    onClick={onClickFn}
    >
      <h3 className="pb-2 max-w-lg">{`${data.poNum}`}</h3>

      <div className="grid grid-rows-3 pt-2">
        <RetroRow label="Value" value={new Intl.NumberFormat().format(data.total)} />      
        <RetroRow label="Receive" value={data.RCVDATE.split(" ")[0]} /> 
        <RetroRow label="Items" value={data.items.length} />
        <RetroRow label="Ref." value={data.ref} />
      </div>
    </div>
  );
};

export default RetroCard;
export {PORetroCard}
