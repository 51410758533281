import { configureStore } from '@reduxjs/toolkit'
import searchSlice from './features/search/searchSlice'
import groupingSlice from './features/grouping/groupingSlice'
import showHide from './features/controlling/showHide'

export const store = configureStore({
    reducer: {
        search: searchSlice,
        grouping: groupingSlice,
        showHide: showHide,
    }
})