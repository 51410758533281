import { createSlice } from "@reduxjs/toolkit"

const searchSlice = createSlice({
    name: "search",
    initialState: {
        value: "",
        pageSize: 100,
    },
    reducers: {
        setKeyword: (state, action) => {            
            state.value = action.payload.keyword
        },
        setPageSize: (state, action) => {
            state.pageSize = action.payload.pageSize
        }
    }
})

export const { setKeyword, setPageSize } = searchSlice.actions
export default searchSlice.reducer